import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  protected authenticated!: boolean;
  constructor(private router: Router, private authService: AuthService) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.authenticated = await this.authService.isLoggedIn();
    if (state.url.includes('/login')) {
      if (this.authenticated) {
        this.router.navigate(['/home']);
        return false;
      } else {
        return true;
      }
    } else {
      if (!this.authenticated) {
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    }
  }
}
